import React from "react";
import image from "../images/eggedBakara.png";

const imageAltText = "EggedBakara image";
const projectList = [
  {
    title: "EggedBakara App",
    description:
      "Egged's staff app is a real-time dashboard tailored for inspectors. Track your performance metrics, meet daily quotas, and gauge monthly goals—all in one place. Stay ahead with insights into colleagues' data, all aimed at optimizing your workday. It's the essential tool for Egged inspectors seeking efficiency and excellence.",
    url: "https://play.google.com/store/apps/details?id=com.egged_bakara",
  },
];

const Portfolio = () => {
  return (
    <section className="padding" id="portfolio">
      <h2 style={{ textAlign: "center" }}>Portfolio</h2>
      <div style={{ display: "flex", flexDirection: "row", paddingTop: "3rem" , paddingLeft: "10rem"}}>
        <div style={{ maxWidth: "40%", alignSelf: "center" }}>
          <img
            src={image}
            style={{
              height: "90%",
              width: "100%",
              objectFit: "cover",
              animation: "1s ease-out 0s 1 slideIn",
            }}
            alt={imageAltText}
          />
        </div>
        <div className="container">
          {projectList.map((project) => (
            <div className="box" key={project.title}>
              <a href={project.url} target="_blank" rel="noopener noreferrer">
                <h3 style={{ flexBasis: "40px" }}>{project.title}</h3>
              </a>
              <p className="small">{project.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
